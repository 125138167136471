body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --header-height: 84px;
  --header-padding-left: 20px;
  --heading-margin-left: 20px;
  --font-size-normal: 16px;

  --dark-white: #fafafa;
  --dark-gray: #4e4e4e;
  --blue: #4e97fe;
  --red: #e60000;
}

::selection {
  background-color: black;
  color: white;
}

.App {
  text-align: center;
}

.Header {
  background-color: var(--dark-white);
  font-size: 16px;
  color: var(--dark-gray);
  width: 100vw;
  height: var(--header-height);
  text-align: left;
  display: flex;
  flex-direction: row;
}

.Header__LogoContainer {
  display: flex;
  cursor: pointer;

  padding-right: 20px;
}

.Header__LogoImageContainer {
  height: var(--header-height);

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.Header__LogoImage {
  height: var(--header-height);
}

.Header__Heading {
  height: var(--header-height);
  margin: 0;
  padding: 0;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
  font-weight: 200;

  margin-left: 20px;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  user-select: none;
  -webkit-user-select: none;
}

.NavBar {
  flex-grow: 1;
  display: flex;
  justify-content: right;
}

.NavBar__TopLevelLinkContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  padding-left: 20px;
  padding-right: 20px;
}

.NavBar__TopLevelLink {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  color: var(--dark-gray);
  font-size: var(--font-size-normal);
  font-weight: bold;

  cursor: pointer;
}

.NavBar__TopLevelLink:hover {
  color: var(--blue);
}

.NavBar__TopLevelLink--active {
  color: var(--blue);
}

.Button {
  color: var(--dark-white);
  background-color: var(--blue);
  font-size: var(--font-size-normal);
  font-weight: bold;

  border: none;
  border-radius: 6px;
  padding: 10px 20px;

  cursor: pointer;
}

.Button:hover {
  filter: contrast(0.8) brightness(1.2);
}

.Button:disabled {
  cursor: not-allowed;
}

.Button--callToAction {
  margin-bottom: 120px;
  min-width: 160px;
  min-height: 80px;
}

.Body--404 {
  padding-top: 40px;
  font-size: 16px;
  line-height: 16px;
}

.FullWidthImageContainer {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}

.FullWidthImage {
  width: 100vw;
}

.ImageCenterCaptionContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: 100%;
  color: white;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  font-size: var(--font-size-normal);
}

.ImageCenterCaption {
  margin: 0;
  font-size: 40px;
  font-family: "Poppins", sans-serif;
}

.ImageCenterCaption--large {
  font-size: 100px;
  font-weight: bold;
}

.Body__Heading {
  font-size: 40px;
  color: var(--dark-gray);
}

h3 {
  font-size: 30px;
  color: var(--dark-gray);
}

.Body__Heading--largeMargin {
  margin-top: 80px;
  margin-bottom: 80px;
}

.Body__Paragraph {
  font-size: 20px;
  color: var(--dark-gray);
  margin-left: 25vw;
  margin-right: 25vw;
  line-height: 30px;
}

.Body__Paragraph {
  margin-bottom: 160px;
}

.CallToActionGalleryContainer {
  width: 100vw;
}

.CallToActionGallery {
  width: 70vw;
  margin: 0 auto;
}

.CallToActionPanelContainer {
  vertical-align: top;
}

.CallToActionPanel {
  width: 35vw;
}

.CallToActionPanel__Corset {
  width: 30vw;
  margin: 0 auto;
}

.CallToActionPanel__Image {
  width: calc(30vw - 2 * 5px);
}

.CallToActionPanel__Heading {
  font-size: 30px;
  color: var(--dark-gray);
}

.CallToActionPanel__Description {
  font-size: 20px;
  color: var(--dark-gray);
}

.Footer__SocialMediaLinks {
  background-color: var(--blue);
  padding-top: 40px;
  padding-bottom: 40px;
}

.SocialMediaLink {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.SocialMediaLink--nonFinal {
  margin-right: 20px;
}

.SocialMediaLink__Background {
  fill: white;
}

.SocialMediaLink__Foreground {
  fill: var(--blue);
}

.Footer__CopyrightNotice {
  color: var(--dark-gray);
  font-size: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.Body--subNavigable,
.Body--tightCenter {
  width: auto;
  margin: auto;
  max-width: 1020px;
  padding: 96px 32px;

  text-align: left;
  vertical-align: top;
  font-size: 20px;
  color: var(--dark-gray);
}

.SubNavigable__Nav {
  display: inline-block;
  width: 255px;
  vertical-align: top;
  line-height: 40px;
}

.SubNavigable__Content,
.TightCenter__Content {
  display: inline-block;
  width: calc(100% - 255px);
}

.SubNavigable__Nav__Link {
  display: block;
  font-size: var(--font-size-normal);
  font-weight: bold;
  cursor: pointer;
}

.SubNavigable__Nav__Link:hover,
.SubNavigable__Nav__Link--active {
  color: var(--blue);
}

.Bold {
  font-weight: bold;
}

.Event {
  padding-top: 40px;
}

.EventLink {
  text-decoration: none;
  color: var(--dark-gray);
}

.EventLink:hover {
  color: var(--blue);
}

.Link {
  text-decoration: none;
  color: var(--blue);
  cursor: pointer;
}

.Link:hover {
  text-decoration: underline;
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

.LargeTopPadding {
  padding-top: 80px;
}

.Scholarship {
  padding-top: 40px;
}

.Scholarship--deadlinePassed {
  opacity: 0.5;
}

.Scholarship__Deadline {
  font-weight: bold;
  color: var(--red);
}

.Scholarship__Deadline--deadlinePassed {
  color: inherit;
}

.MediumTopPadding {
  padding-top: 40px;
}

.MediumBottomPadding {
  padding-bottom: 40px;
}

.SmallTopPadding {
  padding-top: 20px;
}

.SmallBottomPadding {
  padding-bottom: 20px;
}

.DonationForm__Field {
  padding-top: 16px;
  padding-bottom: 16px;
}

.DonationForm__Field__TextInput {
  border-width: 1px;
  border-style: solid;
  border-color: var(--dark-gray);
  border-radius: 4px;
  font-size: 20px;
  padding: 4px;
  color: var(--dark-gray);
  margin: 8px;
}

.DonationForm__Field__TextInput:active {
  border-color: var(--blue);
}

.CheckboxAndLabelContainer {
  display: inline-block;
  white-space: nowrap;
}

.CheckboxAndLabelContainer input,
.CheckboxAndLabelContainer span {
  vertical-align: middle;
}

.DonationForm__Field__Checkbox {
  margin-right: 20px;
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

.HalfOpacity {
  opacity: 0.5;
}
